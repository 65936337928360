import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { lineGraphLayout, rangeSlider } from "../../../utils/plots/style/layouts";
import styles from "./styles.module.css";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import LearningSiteProcessor from "../../data-processors/LearningSiteProcessor";
import { getExpandedPlotParams, getMostUsedBlocksParams, setParams } from "../../../params-analyzer/marty-blocks";
import { FilterUsageOverTime } from "@robotical/analytics-gatherer/dist/types/learningSiteDatabase";

type Props = {
  dataPath: string,
  title?: string,
};

export default function FilterUsage({ dataPath, title }: Props) {
  const [data, setData] = useState<FilterUsageOverTime | null>(null);
  const [period, setPeriod] = useState("per-30"); // per-7, per-30, per-90

  useEffect(() => {
    const asyncFunc = async () => {
      // busy wait for the params to be set
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const params = getMostUsedBlocksParams();
      const expandedPlot = getExpandedPlotParams();

      if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
        if (params.period) {
          setPeriod(params.period);
        } else {
          setParams({ period: period });
        }
      }
    }
    asyncFunc();
  }, []);

  useEffect(() => {
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      setParams({ period: period });
    }
  }, [period]);


  useEffect(() => {
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath + "/" + period)
          .then((fetchedData: FilterUsageOverTime) => {
            setData(fetchedData);
          })
      })
  }, [period]);

  const processedData = LearningSiteProcessor.filterUsageToTraces(data);

  return (
    !!!data ? <LoadingSpinner /> :
      <>
        <div className={styles.parameters}>
          <div className={styles.topN}>
            <label htmlFor="period">Period: </label>
            <select
              id="period"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              <option value="per-7">7 Days</option>
              <option value="per-30">30 Days</option>
              <option value="per-90">90 Days</option>
            </select>
          </div>
        </div>
        <Plot
          key={JSON.stringify(processedData)}
          style={{ width: "100%", height: "100%" }}
          data={processedData?.traces || []}
          layout={{
            ...lineGraphLayout,
            legend: {
              x: 1.09,
            },
            xaxis: {
              ...lineGraphLayout.xaxis,
              //   rangeslider: processedData ? rangeSlider(processedData.smallerDate, processedData.largerDate) : {},
            },
            yaxis: {
              ...lineGraphLayout.yaxis,
              title: "Sessions Count",
            },
            yaxis2: {
              ...lineGraphLayout.yaxis,
              gridcolor: 'rgba(0,0,0,0)',
              zerolinecolor: 'rgba(0,0,0,0)',
              title: "Sessions %",
              overlaying: 'y',
              side: 'right',
              range: [0, 100], // Assuming the percentage range is 0% to 100%
            }
          }}
        />
      </>
  );
}
