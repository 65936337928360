import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import {
  lineGraphLayout,
  rangeSlider,
} from "../../../utils/plots/style/layouts";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager, WarningMessagesOccurrenceByRicRevOverTime, WarningMessagesOccurrenceOverTime } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import { getExpandedPlotParams, getMostUsedBlocksParams, setParams } from "../../../params-analyzer/marty-blocks";
import styles from "./styles.module.css";
import RobotProcessor from "../../data-processors/RobotProcessor";

type Props = {
  dataPath: string,
  title?: string,
}

export default function WarningMessagesOccurenciesByRicRevOverTime({ dataPath, title }: Props) {
  const [data, setData] = useState<WarningMessagesOccurrenceOverTime | null>(null);
  const [ricRevs, setRicRevs] = useState<string[]>([]);
  const [period, setPeriod] = useState("per-90"); // per-7, per-30, per-90
  const [selectedRicRev, setSelectedRicRev] = useState<string>("");

  const [hasRicRevFetched, setHasRicRevFetched] = useState(false);

  const periodParamChangedByUser = useRef(false);
  const ricRevParamChangedByUser = useRef(false);

  useEffect(() => {
    // get all ricRev
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath + "/" + period)
          .then((fetchedData: WarningMessagesOccurrenceByRicRevOverTime) => {
            if (Object.keys(fetchedData).length > 0) {
              setRicRevs(Object.keys(fetchedData));
              setHasRicRevFetched(true);
              const expandedPlot = getExpandedPlotParams();
              if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
                const params = getMostUsedBlocksParams();
                if (!params.ricRev) {
                  setSelectedRicRev("All");
                }
              } else {
                setSelectedRicRev("All");
              }
            }
          });
      });
  }, [period]);


  useEffect(() => {
    const params = getMostUsedBlocksParams();
    const expandedPlot = getExpandedPlotParams();

    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      if (params.period) {
        setPeriod(params.period);
      } else {
        setParams({ period: period });
      }
      if (params.ricRev) {
        setSelectedRicRev(params.ricRev);
      } else {
        setParams({ ricRev: selectedRicRev });
      }
    }
  }, [hasRicRevFetched]);

  useEffect(() => {
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      periodParamChangedByUser.current && setParams({ period: period });
      ricRevParamChangedByUser.current && setParams({ ricRev: selectedRicRev });
    }
  }, [period, selectedRicRev]);

  useEffect(() => {
    if (!selectedRicRev || !period) return;
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath + "/" + period + "/" + selectedRicRev)
          .then((fetchedData: WarningMessagesOccurrenceOverTime) => {
            setData(fetchedData);
          })
      })
  }, [period, selectedRicRev]);

  const onPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    periodParamChangedByUser.current = true;
    setPeriod(e.target.value);
  }

  const onRicRevChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    ricRevParamChangedByUser.current = true;
    setSelectedRicRev(e.target.value);
  }

  const processedData = RobotProcessor.getWarningMessagesOccurrenceOverTime(data);

  return (
    !!!data ? <LoadingSpinner /> :
      <>
        <div className={styles.parameters}>
          <div className={styles.topN}>
            <label htmlFor="period">Period: </label>
            <select
              id="period"
              value={period}
              onChange={onPeriodChange}
            >
              <option value="per-7">7 Days</option>
              <option value="per-30">30 Days</option>
              <option value="per-90">90 Days</option>
            </select>
            <label htmlFor="selectedRicRev">Ric Rev: </label>
            <select
              id="selectedRicRev"
              value={selectedRicRev}
              onChange={onRicRevChange}
            >
              {ricRevs.map((ricRev) => <option key={ricRev} value={ricRev}>{ricRev}</option>)}
            </select>
          </div>
        </div>
        <Plot
          key={JSON.stringify(processedData?.traces)}
          style={{ width: "100%", height: "100%" }}
          data={processedData?.traces || []}
          layout={{
            ...lineGraphLayout,
            xaxis: {
              ...lineGraphLayout.xaxis,
              rangeslider: processedData ? rangeSlider(processedData.smallerDate, processedData.largerDate) : {},
            },
            yaxis: {
              ...lineGraphLayout.yaxis,
              title: "Count",
            }
          }}
        />
      </>
  );
}
