import { MeanDurationByTimezone, PropertyCountOverTime, RetentionRate } from "@robotical/analytics-gatherer/dist";
import { AdoptionCountGetResponse, RetentionRateGetResponse } from "../../types/generic";
import { lineGraphOptions } from "../../utils/plots/style/data-traces";

export default class GenericProcessor {

    static countOverTimeToByTimezonesTraces(data: MeanDurationByTimezone[""] | null) {
        const traces: any[] = [];
        if (!data) return null;
        for (const [key, value] of Object.entries(data)) {
            const adoptionCount = value;
            const x = Object.keys(adoptionCount)
            const y = Object.values(adoptionCount)
            traces.push({
                ...lineGraphOptions(),
                x: x.map(
                    (stringDate) => new Date(stringDate)
                ),
                y: y,
                name: key
            })
        }

        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);
        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }


    static countOverTimeToTraces(data: AdoptionCountGetResponse[""] | null) {
        const traces: any[] = [];
        if (!data) return null;

        const x = Object.keys(data)
        const y = Object.values(data)
        traces.push({
            ...lineGraphOptions(),
            x: x.map(
                (stringDate) => new Date(stringDate)
            ),
            y: y,

        })


        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);
        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

    static retentionRateToTraces(data: RetentionRateGetResponse[""] | null) {
        const traces: any[] = [];
        if (data) {

            const x = Object.keys(data)
            const y = Object.values(data)
            traces.push({
                ...lineGraphOptions(),
                x: x.map(
                    (stringDate) => new Date(stringDate)
                ),
                y: y.map((rateObj) => rateObj.percentage),
                text: y.map((rateObj) => rateObj.total),
                textposition: 'none',
                hovertemplate: [
                    "Count: %{text}",
                    "Rate: %{y:.2f}",
                    "Date: %{x}"
                ].join("<br>"),
                hoverinfo: 'text',
            })
        }


        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

    static smallerAndLargerDate(traces: any[]) {
        let smallerDate = new Date("5021-01-01");
        let largerDate = new Date("1021-01-01");
        for (const trace of traces) {
            if (trace.x[0] < smallerDate) {
                smallerDate = trace.x[0]
            }
            if (trace.x[trace.x.length - 1] > largerDate) {
                largerDate = trace.x[trace.x.length - 1]
            }
        }
        return {
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

    static propertyCountToTraces(data: PropertyCountOverTime | null) {
        const traces: any[] = [];
        if (!data) return null;
        for (const [property, periodGroup] of Object.entries(data)) {
            const x: string[] = [];
            const y: number[] = [];
            for (const [period, count] of Object.entries(periodGroup)) {
                x.push(period);
                y.push(count);
            }
            traces.push({
                ...lineGraphOptions(),
                x: x,
                y: y,
                name: property,
                type: "scatter",
                mode: "lines+markers",
                marker: {
                    size: 8
                }
            });
        }
        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

}