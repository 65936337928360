import { useLocation } from "react-router";
import { NAV_LINKS } from "../../utils/nav-links";
import PlotsArrangement from "../../components/PlotsArrangement/indext";
import PlotFrame from "../../components/PlotFrame";
import styles from "./styles.module.css";
import ActiveUsage from "../../plots/components/learning-site/ActiveUsage";
import { PythonTranslatorActiveUsersOverTimeDescription } from "../../utils/plot-descriptions";

export default function PythonTranslatorAnalyticsPage() {
    const location = useLocation();

    return <div className={styles.container}>
        <h4 className={styles.title}>
            {NAV_LINKS.filter((link) => link.href === location.pathname)[0].title}{" "}
        </h4>
        <PlotsArrangement>
            <PlotFrame title="Active Usage" plotDescription={PythonTranslatorActiveUsersOverTimeDescription}>
                <ActiveUsage dataPath="python-translator/active-usage-over-time" />
            </PlotFrame>
        </PlotsArrangement>
    </div>
}
