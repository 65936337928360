enum PARAMS {
    topN = 'topN',
    expandedPlot = 'expandedPlot',
    period = 'period',
    timezone = 'timezone',
    dateRange = 'dateRange',
    ricRev = 'ricRev',
    OS = 'os',
}

export type Daterange = {
    start: string,
    end: string,
}

export const getMostUsedBlocksParams = () => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);

    const topN = stringToInteger(urlParams.get(PARAMS.topN));
    const period = urlParams.get(PARAMS.period);
    const timezone = urlParams.get(PARAMS.timezone);
    let dateRange: string | null | Daterange = urlParams.get(PARAMS.dateRange);
    if (dateRange) {
        dateRange = { start: dateRange.split(" - ")[0], end: dateRange.split(" - ")[1] }
    }
    const ricRev = urlParams.get(PARAMS.ricRev);
    const os = urlParams.get(PARAMS.OS);

    return { topN, period, timezone, dateRange, ricRev, os };
}

const stringToInteger = (str: string | null) => {
    if (!str) return null;
    try {
        return parseInt(str);
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const getExpandedPlotParams = () => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);

    const expandedPlot = urlParams.get(PARAMS.expandedPlot);

    return { expandedPlot };
}

export const setParams = (params: { [key: string]: any }) => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);

    for (const [key, value] of Object.entries(params)) {
        urlParams.set(key, value);
    }

    const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
}

export const removeAllParams = () => {
    const newUrl = `${window.location.origin}${window.location.pathname}`;
    window.history.replaceState({}, '', newUrl);
}