import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import {
  lineGraphLayout,
  rangeSlider,
} from "../../../utils/plots/style/layouts";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import { RobotWarrantyServiceWarnPercOverTimeGetResponse } from "../../../types/robot";
import RobotProcessor from "../../data-processors/RobotProcessor";

type Props = {
  dataPath: string,
}

export default function RobotWarrantyServiceWarrnings({ dataPath }: Props) {
  const [data, setData] = useState<RobotWarrantyServiceWarnPercOverTimeGetResponse | null>(null);

  useEffect(() => {
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath)
          .then((fetchedData: RobotWarrantyServiceWarnPercOverTimeGetResponse) => {
            setData(fetchedData);
          })
      })
  }, []);

  const processedData = RobotProcessor.warningsPercOverTimeToTraces(data);

  return (
    !!!data ? <LoadingSpinner /> :
      <Plot
        key={JSON.stringify(processedData?.traces)}
        style={{ width: "100%", height: "100%" }}
        data={processedData?.traces || []}
        layout={{
          ...lineGraphLayout,
          xaxis: {
            ...lineGraphLayout.xaxis,
            rangeslider: processedData ? rangeSlider(processedData.smallerDate, processedData.largerDate) : {},
          },
          yaxis: {
            ...lineGraphLayout.yaxis,
            title: "% of Warnings in Warranty Service",
          }
        }}
      />
  );
}
