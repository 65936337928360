import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  StyledModalOverlay,
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledTitle,
  StyledModalTitleContainer,
  StyledCloseSymbol,
} from "./styles";

type ModalPropsTypes = {
  show: boolean;
  closing: boolean;
  children: React.ReactNode;
  title: string;
  onClose: () => void;
};

function Modal({ show, closing, children, onClose, title }: ModalPropsTypes) {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
    if (show) {
      window.scrollTo({ top: 0 });
      document
        .getElementsByTagName("body")[0]
        .setAttribute("style", "overflow: hidden");
    }
  }, [show]);

  const modalContent = show ? (
    <StyledModalOverlay>
      <StyledModal $closing={closing}>
        <StyledModalHeader></StyledModalHeader>
        <StyledModalTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledCloseSymbol onClick={onClose}>X</StyledCloseSymbol>
        </StyledModalTitleContainer>
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root")!
    );
  } else {
    return null;
  }
}
export default Modal;
