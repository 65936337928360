import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import { lineGraphLayout } from "../../../utils/plots/style/layouts";
import styles from "./styles.module.css";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import MartyBlocksProcessor from "../../data-processors/MartyBlocksProcessor";
import { getExpandedPlotParams, getMostUsedBlocksParams, setParams } from "../../../params-analyzer/marty-blocks";
import { MartyBlocksMostUsedBlocksByPeriod } from "@robotical/analytics-gatherer/dist/types/martyBlocksDatabase";

type Props = {
  dataPath: string,
  title?: string,
  yAxesTitle?: string,
};

export default function MostUsedFiltersByPeriodPlot({ dataPath, title, yAxesTitle }: Props) {
  const [data, setData] = useState<MartyBlocksMostUsedBlocksByPeriod | null>(null);
  const [topN, setTopN] = useState(15);
  const [period, setPeriod] = useState("per-30"); // per-7, per-30, per-90
  const topNParamChangedByUser = useRef(false);
  const periodParamChangedByUser = useRef(false);


  useEffect(() => {
    const asyncFunc = async () => {
      // busy wait for the params to be set
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const params = getMostUsedBlocksParams();
      const expandedPlot = getExpandedPlotParams();

      if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
        if (params.topN) {
          setTopN(params.topN);
        } else {
          setParams({ topN: topN });
        }
        if (params.period) {
          setPeriod(params.period);
        } else {
          setParams({ period: period });
        }
      }
    }
    asyncFunc();
  }, []);

  useEffect(() => {
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      topNParamChangedByUser.current && setParams({ topN: topN });
      periodParamChangedByUser.current && setParams({ period: period });
    }
  }, [topN, period]);

  useEffect(() => {
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath + "/" + period)
          .then((fetchedData: MartyBlocksMostUsedBlocksByPeriod) => {
            setData(fetchedData);
          })
      })
  }, [period]);


  const onTopNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopN(parseInt(e.target.value));
    topNParamChangedByUser.current = true;
  };

  const onPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriod(e.target.value);
    periodParamChangedByUser.current = true;
  };


  const truncateNameLength = 30;
  const processedData = MartyBlocksProcessor.countToTracesOverTime(data, topN, truncateNameLength);

  return (
    !!!data ? <LoadingSpinner /> :
      <>
        <div className={styles.parameters}>
          <div className={styles.topN}>
            <label htmlFor="topN">Top N: </label>
            <input
              id="topN"
              type="number"
              min="1"
              value={topN}
              onChange={onTopNChange}
            />
          </div>
          <div className={styles.topN}>
            <label htmlFor="period">Period: </label>
            <select
              id="period"
              value={period}
              onChange={onPeriodChange}
            >
              <option value="per-7">7 Days</option>
              <option value="per-30">30 Days</option>
              <option value="per-90">90 Days</option>
            </select>
          </div>
        </div>
        <Plot
          key={JSON.stringify(processedData)}
          style={{ width: "100%", height: "100%" }}
          data={processedData?.traces || []}
          layout={{
            ...lineGraphLayout,
            xaxis: {
              ...lineGraphLayout.xaxis,
            },
            yaxis: {
              ...lineGraphLayout.yaxis,
              title: yAxesTitle || "Count"
            }
          }}
        />
      </>
  );
}
