import styled, { css } from "styled-components";
import GlobalColors from "../../../styles/colors";
import { RESPONSIVE } from "../../../styles/constants";

const NavigationContainerMediaTablet = css`
  @media screen and (max-width: ${RESPONSIVE.tablet}) {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: unset;
    .navigation-title,
    .navigation-links-main {
      display: none;
    }
    .navigation-sandwich,
    .navigation-sandwich::after,
    .navigation-sandwich::before {
      content: "";
      width: 35px;
      height: 3px;
      border-radius: 2px;
      background-color: ${GlobalColors.DARK_GREY};
      position: absolute;
    }

    .navigation-sandwich-container {
      display: block;
      cursor: pointer;
      margin-top: 15px;
      margin-left: 10px;
      position: relative;
      z-index: 2;
    }

    .navigation-sandwich {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ::after {
        top: 7px;
      }
      ::before {
        top: -7px;
      }
    }

    .navigation-sandwich-clicked {
      visibility: hidden;
      background-color: white;
      z-index: 2;
      ::after {
        transform: rotate(-45deg);
      }
      ::before {
        transform: rotate(45deg);
      }
      ::after,
      ::before {
        transform-origin: center;
        visibility: visible;
        content: "";
        width: 35px;
        height: 3px;
        border-radius: 2px;
        background-color: white;
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 2;
      }
    }

    .navigation-side-drawer-backdrop,
    .navigation-side-drawer {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      backdrop-filter: blur(2px);
    }

    .navigation-side-drawer {
      width: 250px;
      background-color: ${GlobalColors.DARK_GREY};
    }
  }
`;

const navigationLinksStyle = css`
  .navigation-links-main {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    .active {
      border-right: solid 4px ${GlobalColors.PRIMARY};
    }
    li:first-child {
      margin-top: 40px;
    }
    li {
      padding: 10px 3px;
      background: linear-gradient(
          to left,
          ${GlobalColors.DARK_GREY} 50%,
          lightblue 50%
        )
        right;
      background-size: 200% 100%;
      transition: all 0.2s ease-out;
      :hover {
        background-position: left;
        a {
          color: ${GlobalColors.DARK_GREY};
        }
      }
      a {
        transition: all 0.5s ease-out;
        text-decoration: none;
        color: white;
      }
    }
  }
`;

const logoutButtonStyle = css`
  background-color: ${GlobalColors.PRIMARY};
  align-self: flex-end;
  width: 100%;
  border-radius: 4px;
  padding: 9px 14px 9px;
  outline: none;
  :hover {
    opacity: 0.7;
  }
  text-align: center;
  font-family: "Lato Regular";
  font-size: 14px;
  font-weight: bold;
  color: ${GlobalColors.WHITE};
  border: none;
  cursor: pointer;
`;
export const NavigationContainer = styled.div`
  .navigation-sandwich-container,
  .navigation-side-drawer {
    display: none;
  }
  grid-area: navigation;
  background-color: ${GlobalColors.DARK_GREY};
  .navigation-title {
    width: 100%;
    text-align: center;
    h3 {
      color: white;
      text-transform: uppercase;
      span {
        font-weight: 100;
        color: white;
        font-size: 22px;
        text-transform: none;
      }
    }
  }
  .logout-button {
    ${logoutButtonStyle}
  }
  ${navigationLinksStyle}
  ${NavigationContainerMediaTablet}
`;
