import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import {
  lineGraphLayout,
  rangeSlider,
} from "../../../utils/plots/style/layouts";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import { RetentionRateGetResponse } from "../../../types/generic";
import GenericProcessor from "../../data-processors/GenericProcessor";
import styles from "./styles.module.css";
import { getExpandedPlotParams, getMostUsedBlocksParams, setParams } from "../../../params-analyzer/marty-blocks";

type Props = {
  dataPath: string,
  title?: string,
}

export default function LearningSiteRetentionRates({ dataPath, title }: Props) {
  const [data, setData] = useState<RetentionRateGetResponse[""] | null>(null);
  const [period, setPeriod] = useState("per-30"); // per-7, per-30, per-90
  const periodParamChangedByUser = useRef(false);

  useEffect(() => {
    const asyncFunc = async () => {
      // busy wait for the params to be set
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const params = getMostUsedBlocksParams();
      const expandedPlot = getExpandedPlotParams();

      if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
        if (params.period) {
          setPeriod(params.period);
        } else {
          setParams({ period: period });
        }
      }
    }
    asyncFunc();
  }, []);

  useEffect(() => {
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      periodParamChangedByUser.current && setParams({ period: period });
    }
  }, [period]);

  useEffect(() => {
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath + "/" + period)
          .then((fetchedData: RetentionRateGetResponse[""]) => {
            setData(fetchedData)
          })
      })
  }, [period]);

  const onPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    periodParamChangedByUser.current = true;
    setPeriod(e.target.value);
  }

  const processedData = GenericProcessor.retentionRateToTraces(data);

  return (
    !!!data ? <LoadingSpinner /> :
      <>
        <div className={styles.parameters}>
          <div className={styles.topN}>
            <label htmlFor="period">Period: </label>
            <select
              id="period"
              value={period}
              onChange={onPeriodChange}
            >
              <option value="per-7">7 Days</option>
              <option value="per-30">30 Days</option>
              <option value="per-90">90 Days</option>
            </select>
          </div>
        </div>
        <Plot
          key={JSON.stringify(processedData?.traces)}
          style={{ width: "100%", height: "100%" }}
          data={processedData?.traces || []}
          layout={{
            ...lineGraphLayout,
            xaxis: {
              ...lineGraphLayout.xaxis,
              rangeslider: processedData ? rangeSlider(processedData.smallerDate, processedData.largerDate) : {},
            },
            yaxis: {
              ...lineGraphLayout.yaxis,
              title: "Retention Rate",
            }
          }}
        />
      </>
  );
}
