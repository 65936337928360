import { useLocation } from "react-router";
import { NAV_LINKS } from "../../utils/nav-links";
import PlotsArrangement from "../../components/PlotsArrangement/indext";
import PlotFrame from "../../components/PlotFrame";
import LearningSiteAdoptionCount from "../../plots/components/learning-site/LearningSiteAdoptionCount";
import { AdoptionCountDescription, DownloadedLessonsDescription, DropInActiveUsageOverTimeDescription, DropInLessonUsageOverTimeDescription, FilterUsageOverTimeDescription, LSActiveUsageOverTimeDescription, MostUsedFiltersDescription, MostUsedFiltersOverTimeDescription, MostUsedSearchTermsDescription, MostUsedSearchTermsOverTimeDescription, SearchBarUsageOverTimeDescription, VisitedLessonsDescription } from "../../utils/plot-descriptions";
import styles from "./styles.module.css";
import LearningSiteRetentionRates from "../../plots/components/learning-site/LearningSiteRetentionRates";
import LearningSiteLessonsCount from "../../plots/components/learning-site/LearningSiteLessonsCount";
import ActiveUsage from "../../plots/components/learning-site/ActiveUsage";
import FilterUsage from "../../plots/components/learning-site/FilterUsage";
import MostUsedFiltersPlot from "../../plots/components/learning-site/MostUsedFiltersPlot";
import MostUsedFiltersByPeriodPlot from "../../plots/components/learning-site/MostUsedFiltersByPeriodPlot";
import SearchBarUsage from "../../plots/components/learning-site/SearchBarUsage";
import DropInActiveUsageByPeriodPlot from "../../plots/components/learning-site/DropInActiveUsageByPeriodPlot";

export default function LearningSiteAnalyticsPageCmp() {
    const location = useLocation();

    return <div className={styles.container}>
        <h4 className={styles.title}>
            {NAV_LINKS.filter((link) => link.href === location.pathname)[0].title}{" "}
        </h4>
        <PlotsArrangement>
            <PlotFrame title="Apodtion Count" plotDescription={AdoptionCountDescription}>
                <LearningSiteAdoptionCount dataPath="learning-site/adoption" />
            </PlotFrame>
            <PlotFrame title="Retention Rates" plotDescription={"RetentionRateDescription"}>
                <LearningSiteRetentionRates dataPath="learning-site/retention" />
            </PlotFrame>
        </PlotsArrangement>
        {/* USAGES */}
        <PlotsArrangement>
            <PlotFrame title="Active Usage" plotDescription={LSActiveUsageOverTimeDescription}>
                <ActiveUsage dataPath="learning-site/active-usage-over-time" />
            </PlotFrame>
        </PlotsArrangement>
        <PlotsArrangement>
            <PlotFrame title="Filter Usage" plotDescription={FilterUsageOverTimeDescription}>
                <FilterUsage dataPath="learning-site/filter-usage-over-time" />
            </PlotFrame>
            <PlotFrame title="Search Bar Usage" plotDescription={SearchBarUsageOverTimeDescription}>
                <SearchBarUsage dataPath="learning-site/search-bar-usage-over-time" />
            </PlotFrame>
        </PlotsArrangement>
        {/* MOST LESSONS */}
        <PlotsArrangement>
            <PlotFrame title="Most Visited Lessons" plotDescription={VisitedLessonsDescription}>
                <MostUsedFiltersPlot dataPath="learning-site/most-visited-lessons" />
            </PlotFrame>
            <PlotFrame title="Most Visited Lessons Over Time" plotDescription={VisitedLessonsDescription}>
                <MostUsedFiltersByPeriodPlot dataPath="learning-site/most-visited-lessons-by-period" />
            </PlotFrame>
        </PlotsArrangement>
        <PlotsArrangement>
            <PlotFrame title="Most Downloaded Lessons" plotDescription={DownloadedLessonsDescription}>
                <MostUsedFiltersPlot dataPath="learning-site/most-downloaded-lessons" />
            </PlotFrame>
            <PlotFrame title="Most Downloaded Lessons Over Time" plotDescription={DownloadedLessonsDescription}>
                <MostUsedFiltersByPeriodPlot dataPath="learning-site/most-downloaded-lessons-by-period" />
            </PlotFrame>
        </PlotsArrangement>
        {/* MOST USED FILTERS */}
        <PlotsArrangement>
            <PlotFrame title="Most Used Filters" plotDescription={MostUsedFiltersDescription}>
                <MostUsedFiltersPlot dataPath="learning-site/most-used-filters" />
            </PlotFrame>
            <PlotFrame title="Most Used Filters Over Time" plotDescription={MostUsedFiltersOverTimeDescription}>
                <MostUsedFiltersByPeriodPlot dataPath="learning-site/most-used-filters-by-period" />
            </PlotFrame>
        </PlotsArrangement>
        {/* MOST USED SEARCH TERMS */}
        <PlotsArrangement>
            <PlotFrame title="Most Used Search Terms" plotDescription={MostUsedSearchTermsDescription}>
                <MostUsedFiltersPlot dataPath="learning-site/most-used-search-terms" />
            </PlotFrame>
            <PlotFrame title="Most Used Search Terms Over Time" plotDescription={MostUsedSearchTermsOverTimeDescription}>
                <MostUsedFiltersByPeriodPlot dataPath="learning-site/most-used-search-terms-by-period" />
            </PlotFrame>
        </PlotsArrangement>
        {/* Drops */}
        <PlotsArrangement>
            <PlotFrame title="Drop in active usage" plotDescription={DropInActiveUsageOverTimeDescription}>
                <DropInActiveUsageByPeriodPlot dataPath="learning-site/drop-in-usage-over-time" />
            </PlotFrame>
            <PlotFrame title="Drop in lesson usage" plotDescription={DropInLessonUsageOverTimeDescription}>
                <DropInActiveUsageByPeriodPlot dataPath="learning-site/drop-in-lesson-usage-over-time" />
            </PlotFrame>
        </PlotsArrangement>
    </div>
}
