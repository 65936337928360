import React, { useEffect, useState } from "react";
import InfoHover from "../InfoHover";
import Modal from "../Modal";
import { Container, InModalPlotDescription } from "./styles";
import { ReactSVG } from "react-svg";
import questionMark from "../../assets/images/question-mark.svg";
import { getExpandedPlotParams, removeAllParams, setParams } from "../../params-analyzer/marty-blocks";

type PlotFrameProps = {
  title: string;
  children: React.ReactNode;
  plotDescription: string;
};

export default function PlotFrame({ title, children, plotDescription }: PlotFrameProps) {
  const [isPlotMaximised, setIsPlotMaximised] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);

  useEffect(() => {
    const params = getExpandedPlotParams();
    if (params.expandedPlot) {
      setIsPlotMaximised(params.expandedPlot === title.replaceAll(" ", "-").toLowerCase());
    } else {
      if (isPlotMaximised) {
        setParams({ expandedPlot: title.replaceAll(" ", "-").toLowerCase() });
      }
    }
  }, []);

  useEffect(() => {
    if (isPlotMaximised) {
      setParams({ expandedPlot: title.replaceAll(" ", "-").toLowerCase() });
    }
  }
    , [isPlotMaximised]);

  const maximisePlotHandler = () => {
    setIsPlotMaximised(true);
  };

  const closeModalHandler = () => {
    const timer = setTimeout(() => {
      setIsPlotMaximised(false);
      setIsModalClosing(false);
      document
        .getElementsByTagName("body")[0]
        .setAttribute("style", "overflow: auto");
      clearTimeout(timer);
    }, 700);
    setIsModalClosing(true);

    // Clear the params and reload the page
    removeAllParams();
    window.location.reload();
  };

  useEffect(() => {
    const params = getExpandedPlotParams();
    if (params.expandedPlot) {
      const isThisPlotMaximised = params.expandedPlot === title.replaceAll(" ", "-").toLowerCase();
      if (isThisPlotMaximised) {
        setShouldFetchData(true);
      } else {
        setShouldFetchData(false);
      }
    } else {
      setShouldFetchData(true);
    }
  }, [shouldFetchData]);

  if (!shouldFetchData) {
    return null;
  }

  return (
    <Container>
      <Modal
        show={isPlotMaximised}
        closing={isModalClosing}
        title={title}
        onClose={closeModalHandler}
      >
        {React.Children.map(children, child => {
          // Only clone the element if it's a valid React element
          if (React.isValidElement(child)) {
            //@ts-ignore
            return React.cloneElement(child, { ...child.props, title: title });
          }
          return child;
        })}
        <InModalPlotDescription>{plotDescription.replaceAll("<br>", " ")}</InModalPlotDescription>
      </Modal>
      <div>
        <div className="title-container">
          <div>
            <h4 className="title">{title}</h4>
            <InfoHover
              infoText={plotDescription}
              tooltipId="plot-description-tooltip"
            >
              <ReactSVG
                src={questionMark}
                width={10}
                height={10}
                className="question-mark-wrapper"
              />
            </InfoHover>
          </div>
          <span className="maximise-plot" onClick={maximisePlotHandler}>
            ▣
          </span>
        </div>
        <hr />
      </div>
      <div className="plot-container">
        <div className="plot-figure">{React.Children.map(children, child => {
          // Only clone the element if it's a valid React element
          if (React.isValidElement(child)) {
            //@ts-ignore
            return React.cloneElement(child, { ...child.props, title: title });
          }
          return child;
        })}</div>
      </div>
    </Container>
  );
}
