import styled from "styled-components";


export const Container = styled.div `
    margin: 20px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    grid-auto-flow: row;
    row-gap: 20px;
    column-gap: 20px;
`;