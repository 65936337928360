const CREDENTIALS_COOKIE_NAME = "marty-analytics-user-credentials";

export class CredentialsCookie {
  public static getCookie = (): boolean | null => {
    return localStorage.getItem(CREDENTIALS_COOKIE_NAME) === "true";
  };

  public static setCookie = (isLoggedIn: boolean) => {
    localStorage.setItem(CREDENTIALS_COOKIE_NAME, isLoggedIn + "");
  };

  public static doesCookieExist = (): boolean => {
    return !!localStorage.getItem(CREDENTIALS_COOKIE_NAME);
  };

  public static removeCookie = () => {
    localStorage.removeItem(CREDENTIALS_COOKIE_NAME);
  };
}
