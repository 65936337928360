import { SpanStyled } from "./styles";

type InfoHoverProps = {
    children: React.ReactNode;
    tooltipId: string;
    infoText: string;
};

function InfoHover({children, tooltipId, infoText}:InfoHoverProps) {
    return (
        <SpanStyled data-tip={infoText} data-for={tooltipId}>{children}</SpanStyled>
    );
}


export default InfoHover;