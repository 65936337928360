import { CountGetResponse } from "../../types/generic";
import { Data } from "plotly.js";
import { truncateTraceName } from "../../utils/plots/style/layouts";
import { MartyBlocksMostUsedBlocksByPeriod } from "@robotical/analytics-gatherer/dist/types/martyBlocksDatabase";
import GenericProcessor from "./GenericProcessor";

export default class MartyBlocksProcessor {

    static countToTraces(data: CountGetResponse | null, topN: number, truncateNameLength = 10) {
        if (!data) return [{}] as Data[];
        const sorted = Object.entries(data).sort((a, b) => b[1] - a[1]);
        const topNEntries = sorted.slice(0, topN);

        // sort alphabetically
        topNEntries.sort((a, b) => {
            if (a[0] < b[0]) return -1;
            return 1;
        });

        const trace: Data = {
            labels: topNEntries.map(([key, value]) => truncateTraceName(key, truncateNameLength)),
            values: topNEntries.map(([key, value]) => value),
            name: "Count",
            type: "pie",
            // sort: false,
        };

        return [trace];
    }

    static countToTracesOverTime(data: MartyBlocksMostUsedBlocksByPeriod | null, topN: number, truncateNameLength = 10) {
        // topN is the number of traces to show based on the mean of each trace
        // get the mean of all traces, sort them, and take the topN
        if (!data) return null;
        const traces: any[] = [];

        const formattedData: {
            dates: string[];
            [blockName: string]: (number | string)[]
        } = {
            dates: [],
        };

        for (const date in data) {
            formattedData.dates.push(date);
            const blockNames = Object.keys(data[date]);
            for (const blockName of blockNames) {
                if (!formattedData[blockName]) {
                    formattedData[blockName] = [];
                }
                formattedData[blockName].push(data[date][blockName]);
            }
        }

        const blockNames = Object.keys(formattedData).filter(key => key !== 'dates');
        for (const blockName of blockNames) {
            const trace: Data = {
                x: formattedData.dates,
                y: formattedData[blockName],
                name: truncateTraceName(blockName, truncateNameLength),
                type: "scatter"
            };
            traces.push(trace);
        }

        // sort traces by mean
        traces.sort((a, b) => {
            const aMean = a.y.reduce((a: number, b: number) => a + b, 0) / a.y.length;
            const bMean = b.y.reduce((a: number, b: number) => a + b, 0) / b.y.length;
            return bMean - aMean;
        });

        // take the topN
        traces.splice(topN);

        // sort alphabetically
        traces.sort((a, b) => {
            if (a.name < b.name) return -1;
            return 1;
        });

        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);
        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

}
