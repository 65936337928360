import { useLocation } from "react-router";
import { NAV_LINKS } from "../../utils/nav-links";
import PlotsArrangement from "../../components/PlotsArrangement/indext";
import PlotFrame from "../../components/PlotFrame";
import MostUsedBlocksPlot from "../../plots/components/marty-blocks/MostUsedBlocksPlot";
import { MostUsedBlocksDescription } from "../../utils/plot-descriptions";
import styles from "./styles.module.css";
import MostUsedBlocksByPeriodPlot from "../../plots/components/marty-blocks/MostUsedBlocksByPeriodPlot";

export default function MartyBlocksAnalyticsPage() {
    const location = useLocation();

    return <div className={styles.container}>
        <h4 className={styles.title}>
            {NAV_LINKS.filter((link) => link.href === location.pathname)[0].title}{" "}
        </h4>
        <PlotsArrangement>
            <PlotFrame title="Most Used Blocks" plotDescription={MostUsedBlocksDescription}>
                <MostUsedBlocksPlot dataPath="marty-blocks/most-used-blocks" />
            </PlotFrame>
        </PlotsArrangement>
        <PlotsArrangement>
            <PlotFrame title="Most Used Blocks Over Time" plotDescription={MostUsedBlocksDescription}>
                <MostUsedBlocksByPeriodPlot dataPath="marty-blocks/most-used-blocks-by-period" />
            </PlotFrame>
        </PlotsArrangement>
    </div>
}
