import { ActiveUsageOverTime, DropInUsage, DropInUsageOverTime, FilterUsageOverTime, LessonsCountPerDay, SearchBarUsageOverTime } from "@robotical/analytics-gatherer/dist";
import { lineGraphOptions } from "../../utils/plots/style/data-traces";
import GenericProcessor from "./GenericProcessor";
import { LessonsCountTrace } from "../../types/learning-site";
import { truncateTraceName } from "../../utils/plots/style/layouts";

export default class LearningSiteProcessor {

    static lessonCountToTraces(data: LessonsCountPerDay | null) {
        const traces: any[] = [];
        if (!data) return null;
        const lineGraphSeriesData = LearningSiteProcessor.convertLessonsToLineGraphSeries(data);
        if (!lineGraphSeriesData) return null;
        for (const [key, value] of Object.entries(lineGraphSeriesData)) {
            const lessonCount = value;
            traces.push({
                ...lineGraphOptions(),
                ...lessonCount
            })
        }
        console.log(traces)
        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }


    static convertLessonsToLineGraphSeries(input: LessonsCountPerDay | null): LessonsCountTrace | null {
        if (!input) return null;
        const output: LessonsCountTrace = {};

        for (const [date, lessons] of Object.entries(input)) {
            for (const [lessonId, count] of Object.entries(lessons)) {
                if (!output[lessonId]) {
                    output[lessonId] = {
                        x: [],
                        y: [],
                        name: truncateTraceName(lessonId, 20),
                    }
                }
                output[lessonId].x.push(new Date(date));
                output[lessonId].y.push(count);
            }
        }
        return output;
    }

    static filterUsageToTraces(data: FilterUsageOverTime | null) {
        const traces: any[] = [];
        if (!data) return null;

        const dates = Object.keys(data);
        const filterUsageCount = dates.map((date) => data[date].filterUsedSessionsCount);
        const totalSessionsCount = dates.map((date) => data[date].totalSessionsCount);

        // another trace with the percentage as bar chart. on its own y axis from 0 to 1
        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: filterUsageCount.map((count, index) => Math.round((count / totalSessionsCount[index]) * 100)),
            yaxis: "y2",
            type: "bar",
            name: "Filter Percentage",
            marker: {
                color: 'rgba(50, 171, 96, 0.2)',
                line: {
                    color: 'rgba(50, 171, 96, 0.2)',
                    // width: 5
                },
            },
            width: 100000000,
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Filter Usage % %{y}`,
            ].join("<br>"),
        });

        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: filterUsageCount,
            mode: "lines+markers",
            name: "Filter Count",
            text: filterUsageCount.map((count, index) => count / totalSessionsCount[index]),
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Filter Usage Count %{y:,.0f}`,
                `Filter Usage Percentage: %{text:,.1%}`,
            ].join("<br>"),
        });

        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: totalSessionsCount,
            mode: "lines+markers",
            name: "Total Count",
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Total Sessions Count: %{y:,.0f}`,
            ].join("<br>"),
        });


        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

    static searchBarUsageToTraces(data: SearchBarUsageOverTime | null) {
        const traces: any[] = [];
        if (!data) return null;

        const dates = Object.keys(data);
        const searchBarUsageCount = dates.map((date) => data[date].searchBarUsedSessionsCount);
        const totalSessionsCount = dates.map((date) => data[date].totalSessionsCount);

        // another trace with the percentage as bar chart. on its own y axis from 0 to 1
        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: searchBarUsageCount.map((count, index) => Math.round((count / totalSessionsCount[index]) * 100)),
            yaxis: "y2",
            type: "bar",
            name: "Search Bar Percentage",
            marker: {
                color: 'rgba(50, 171, 96, 0.2)',
                line: {
                    color: 'rgba(50, 171, 96, 0.2)',
                    // width: 5
                },
            },
            width: 100000000,
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Search Bar Usage % %{y}`,
            ].join("<br>"),
        });

        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: searchBarUsageCount,
            mode: "lines+markers",
            name: "Search Bar Count",
            text: searchBarUsageCount.map((count, index) => count / totalSessionsCount[index]),
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Search Bar Usage Count %{y:,.0f}`,
                `Search Bar Usage Percentage: %{text:,.1%}`,
            ].join("<br>"),
        });

        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: totalSessionsCount,
            mode: "lines+markers",
            name: "Total Count",
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Total Sessions Count: %{y:,.0f}`,
            ].join("<br>"),
        });


        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }


    static activeUsageToTraces(data: ActiveUsageOverTime | null) {
        const traces: any[] = [];
        if (!data) return null;

        const dates = Object.keys(data);
        const activeSessionsCount = dates.map((date) => data[date].activeSessionsCount);
        const totalSessionsCount = dates.map((date) => data[date].totalSessionsCount);


        // another trace with the percentage as bar chart. on its own y axis from 0 to 1
        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: activeSessionsCount.map((count, index) => Math.round((count / totalSessionsCount[index]) * 100)),
            yaxis: "y2",
            type: "bar",
            name: "Active Percentage",
            marker: {
                color: 'rgba(50, 171, 96, 0.2)',
                line: {
                    color: 'rgba(50, 171, 96, 0.2)',
                    // width: 5
                },
            },
            width: 100000000,
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Active Sessions % %{y}`,
            ].join("<br>"),
        });

        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: activeSessionsCount,
            mode: "lines+markers",
            name: "Active Count",
            text: activeSessionsCount.map((count, index) => count / totalSessionsCount[index]),
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Active Sessions Count %{y:,.0f}`,
                `Active Sessions Percentage: %{text:,.1%}`,
            ].join("<br>"),
        });

        traces.push({
            ...lineGraphOptions(),
            x: dates.map((stringDate) => new Date(stringDate)),
            y: totalSessionsCount,
            mode: "lines+markers",
            name: "Total Count",
            hoverinfo: 'text',
            hovertemplate: [
                "Date: %{x}",
                `Total Sessions Count: %{y:,.0f}`,
            ].join("<br>"),
        });


        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }

    static countToTracesOverTime(data: DropInUsageOverTime | null, truncateNameLength = 10) {
        // topN is the number of traces to show based on the mean of each trace
        // get the mean of all traces, sort them, and take the topN
        if (!data) return null;
        const traces: any[] = [];

        const formattedData: {
            dates: string[];
            [userType: string]: (number | string)[]
        } = {
            dates: [],
        };

        const userTypeMap = {
            countOfUsersWhoAdoptedButDidntReturn: "Adopted But Didn't Return %",
            newUsersCount: "New Users %",
            returnedUsersCount: "Returned Users %",
            totalUsersCount: "Total Users",
        }

        for (const date in data) {
            formattedData.dates.push(date);
            const userTypes = Object.keys(data[date]);
            for (const userType of userTypes) {
                const userTypeFormatted = userTypeMap[userType as keyof typeof userTypeMap];
                if (userType === "totalUsersCount") {
                    !formattedData[userTypeFormatted] && (formattedData[userTypeFormatted] = []);
                    formattedData[userTypeFormatted].push(data[date][userType]);
                }
                if (userType === "newUsersCount") {
                    !formattedData[userTypeFormatted] && (formattedData[userTypeFormatted] = []);
                    const totalUsersCount = data[date]["totalUsersCount"];
                    const newUsersCount = data[date]["newUsersCount"];
                    formattedData[userTypeFormatted].push(newUsersCount / totalUsersCount);
                }
                if (userType === "returnedUsersCount") {
                    !formattedData[userTypeFormatted] && (formattedData[userTypeFormatted] = []);
                    const totalUsersCount = data[date]["totalUsersCount"];
                    const returnedUsersCount = data[date]["returnedUsersCount"];
                    formattedData[userTypeFormatted].push(returnedUsersCount / totalUsersCount);
                }
                if (userType === "countOfUsersWhoAdoptedButDidntReturn") {
                    !formattedData[userTypeFormatted] && (formattedData[userTypeFormatted] = []);
                    const totalUsersCount = data[date]["totalUsersCount"];
                    const countOfUsersWhoAdoptedButDidntReturn = data[date]["countOfUsersWhoAdoptedButDidntReturn"];
                    formattedData[userTypeFormatted].push(countOfUsersWhoAdoptedButDidntReturn / totalUsersCount);
                }
            }
        }
        traces.push({
            x: formattedData.dates,
            y: formattedData[userTypeMap["newUsersCount"]],
            name: truncateTraceName(userTypeMap["newUsersCount"], truncateNameLength),
            type: "scatter",
            hoverinfo: 'text',
            text: formattedData[userTypeMap["totalUsersCount"]],
            hovertemplate: [
                "Date: %{x}",
                `New Users %: %{y:,.1}`,
                `Total Users: %{text}`,
            ].join("<br>"),
        });
        traces.push({
            x: formattedData.dates,
            y: formattedData[userTypeMap["returnedUsersCount"]],
            name: truncateTraceName(userTypeMap["returnedUsersCount"], truncateNameLength),
            type: "scatter",
            hoverinfo: 'text',
            text: formattedData[userTypeMap["totalUsersCount"]],
            hovertemplate: [
                "Date: %{x}",
                `Returned Users %: %{y:,.1}`,
                `Total Users: %{text}`,
            ].join("<br>"),
        });
        traces.push({
            x: formattedData.dates,
            y: formattedData[userTypeMap["countOfUsersWhoAdoptedButDidntReturn"]],
            name: truncateTraceName(userTypeMap["countOfUsersWhoAdoptedButDidntReturn"], truncateNameLength),
            type: "scatter",
            hoverinfo: 'text',
            text: formattedData[userTypeMap["totalUsersCount"]],
            hovertemplate: [
                "Date: %{x}",
                `Adopted But Didn't Return %: %{y:,.1%}`,
                `Total Users: %{text}`,
            ].join("<br>"),
        });

        // sort alphabetically
        traces.sort((a, b) => {
            if (a.name < b.name) return -1;
            return 1;
        });

        const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);
        return {
            traces: traces,
            smallerDate: smallerDate,
            largerDate: largerDate
        }
    }
}
