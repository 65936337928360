import styled from "styled-components";
import GlobalColors from "../../styles/colors";

export const Container = styled.div`
  background-color: ${GlobalColors.DARK_GREY};
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  .login-div {
    background: linear-gradient(
      0.45turn,
      ${GlobalColors.LIGHT_GREY},
      ${GlobalColors.DARK_GREY}
    );
    height: 200px;
    width: 300px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    .login-form {
      display: grid;
      justify-content: center;
      align-content: center;
      row-gap: 10px;
      height: 70%;
      p {
        color: ${GlobalColors.LIFE_RING_RED};
        font-size: 20px;
        font-weight: bold;
        background: linear-gradient(rgba(255,255,255, .4), rgba(255,255,255, .4));
        border-radius: 2px;
      }
      button {
        background-color: ${GlobalColors.PRIMARY};
        border-radius: 4px;
        padding: 9px 14px 9px;
        outline: none;
        :hover {
          opacity: 0.7;
        }
        text-align: center;
        font-family: "Lato Regular";
        font-size: 14px;
        font-weight: bold;
        color: ${GlobalColors.WHITE};
        border: none;
        cursor: pointer;
      }
    }
  }
`;
