export const MAX_WIDTH = "1380px";
export const BORDER_RADIUS = "4px";
export const CONTAINER_HEIGHT = "300px";
export const PADDING_DEFAULT = "10px";

export const RESPONSIVE = {
  mobileSmaller: "400px",
  mobile: "500px",
  tablet: "768px",
  laptop: "1024px",
};

export const PLOT_DIMENSIONS = {
  width: 300,
  height: 250,
};

export const FONT_FAMILY = {
  LATO_REGULAR: "Lato Regular",
  NOODLE_OBLIQUE: "BigNoodleTitling Oblique",
  NOODLE_REGULAR: "BigNoodleTitling Regular",
}