import { useContext, useEffect, useState } from "react";
import UserCredentialsContext from "../../store/user-credentials-context";
import GlobalColors from "../../styles/colors";
import { CredentialsCookie } from "../../utils/cookies/user-credentials";
import Navigation from "./Navigation";
import { Container, MainContentContainer } from "./styles";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router";

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  const navigate = useNavigate();
  const credentialsContext = useContext(UserCredentialsContext);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean | null>(credentialsContext.isLoggedIn);

  useEffect(() => {
    if (credentialsContext.isLoggedIn || CredentialsCookie.getCookie()) {
      setIsUserLoggedIn(true);
      CredentialsCookie.setCookie(true);
    } else {
      navigate("/login");
      setIsUserLoggedIn(false);
      CredentialsCookie.setCookie(false);
    }
  }, [credentialsContext.isLoggedIn]);

  return isUserLoggedIn ? (
    <Container>
      <ReactTooltip
        id="plot-description-tooltip"
        effect="solid"
        backgroundColor={GlobalColors.PRIMARY}
        html={true}
        key={new Date().toISOString()}
      />
      <Navigation />
      <MainContentContainer>{children}</MainContentContainer>
    </Container>
  ) : (
    <>{children}</>
  );
}
