import styled, { css } from "styled-components";
import GlobalColors from "../../styles/colors";
import { RESPONSIVE } from "../../styles/constants";

const ContainerMediaTablet = css`
  @media screen and (max-width: ${RESPONSIVE.tablet}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100vh;
    grid-template-areas: "main-content";
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(230px, 20%) 1fr;
  grid-template-rows: 100vh;
  grid-template-areas: "navigation main-content";
  #plot-description-tooltip {
    background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
    color: white;
    font-family: "Lato Regular";
  }
  ${ContainerMediaTablet}
`;

export const MainContentContainer = styled.div`
  grid-area: main-content;
  /* background-color: ${GlobalColors.LIGHT_GREY}; */
  background: linear-gradient(.45turn, ${GlobalColors.LIGHT_GREY}, ${GlobalColors.DARK_GREY});
  overflow-y: scroll;
`;
