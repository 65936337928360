import React, { useEffect, useState } from "react";
import {
  Container,
  LoadingSpinner1,
  LoadingSpinner2,
  LoadingSpinner3,
  LoadingSpinner4,
  LoadingSpinner5,
} from "./styles";

export default function LoadingSpinner() {
  const [spinner, setSpinner] = useState<React.ReactNode>();

  useEffect(() => {
    const spinnersArray = [
      <LoadingSpinner1 key="LoadingSpinner1"/>,
      <LoadingSpinner2 key="LoadingSpinner2">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingSpinner2>,
      <LoadingSpinner3 key="LoadingSpinner3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingSpinner3>,
      <LoadingSpinner4 key="LoadingSpinner4">
        <div></div>
        <div></div>
      </LoadingSpinner4>,
      <LoadingSpinner5 key="LoadingSpinner5">
        <div></div>
      </LoadingSpinner5>,
    ];
    const randomSpinnerIdx = Math.floor(Math.random() * spinnersArray.length);
    setSpinner(spinnersArray[randomSpinnerIdx]);
  }, []);
  return <Container>{spinner}</Container>;
}
