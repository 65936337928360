import { Transform } from "plotly.js";
import GlobalColors from "../../../styles/colors";

const COLORS = [
  GlobalColors.PRIMARY,
  GlobalColors.DARK_PRIMARY,
  GlobalColors.HOVER_PRIMARY,
  GlobalColors.SECONDARY,
  GlobalColors.HOVER_SECONDARY,
  GlobalColors.LIFE_RING_RED,
];

const randomColor = () => {
  const idx = Math.floor(Math.random() * COLORS.length);
  return COLORS[idx];
};

export const barPlotMarker = () => {
  return { color: randomColor(), opacity: 0.8 };
};

export const barPlotTransforms: Partial<Transform>[] = [
  {
    type: "sort",
    target: "y",
    order: "descending",
  },
];
export const lineGraphOptions = (): any => {
  return {
    type: "scatter",
    line: {
      color: randomColor(),
      width: 1,
      shape: "spline"
    },
  };
};

export const colorsForMultipleGroups = [
  GlobalColors.DARK_PRIMARY,
  GlobalColors.HOVER_PRIMARY,
  GlobalColors.PRIMARY,
  GlobalColors.SECONDARY,
];
