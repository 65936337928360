import styled from "styled-components";
import GlobalColors from "../../styles/colors";
import { FONT_FAMILY, PLOT_DIMENSIONS } from "../../styles/constants";

export const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  /* width: ${PLOT_DIMENSIONS.width + "px"}; */
  width: 100%;
  justify-self: center;
  border-radius: 5px;
  background-color: ${GlobalColors.DARK_GREY};
  padding-bottom: 5px;

  .plot-figure {
    height: ${PLOT_DIMENSIONS.height + "px"};
  }

  .question-mark-wrapper {
    width: 15px;
    height: 15px;
    display: inline-block;
    svg {
      fill: ${GlobalColors.PRIMARY};
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin: 5px;
    text-transform: uppercase;
    color: white;
    font-weight: 100;
    font-family: ${FONT_FAMILY.NOODLE_OBLIQUE};
    display: inline-block;
  }

  .maximise-plot {
    color: white;
    cursor: pointer;
    align-self: center;
    font-size: 18px;
    padding: 5px 7px;
    border-radius: 50%;
    text-transform: uppercase;
    font-family: ${FONT_FAMILY.LATO_REGULAR};
    transition: all 0.2s;
    :hover {
      color: ${GlobalColors.HOVER_PRIMARY};
    }
  }
`;

export const InModalPlotDescription = styled.div`
  color: white;
  font-family: ${FONT_FAMILY.LATO_REGULAR};
  font-size: 18px;
`;
