import { useLocation } from "react-router";
import { NAV_LINKS } from "../../utils/nav-links";
import PlotsArrangement from "../../components/PlotsArrangement/indext";
import PlotFrame from "../../components/PlotFrame";
import AppAdoptionCount from "../../plots/components/app/AppAdoptionCount";
import {
    App_AdoptionCount,
    App_OsCountOverTime,
    App_RetentionRates,
    App_VisitedScreensOverTime,
    App_AppVersionCountOverTime,
    App_DeviceModelCountOverTime,
    App_DeviceVersionCountOverTime
} from "../../utils/plot-descriptions";
import styles from "./styles.module.css";
import AppRetentionRates from "../../plots/components/app/AppRetentionRates";
import PropertyCount from "../../plots/components/PropertyCount/index";
import NestedPropertyCount from "../../plots/components/NestedPropertyCount";

export default function AppAnalyticsPageCmp() {
    const location = useLocation();


    return <div className={styles.container}>
        <h4 className={styles.title}>
            {NAV_LINKS.filter((link) => link.href === location.pathname)[0].title}{" "}
        </h4>
        {/* <PlotsArrangement>
            <PlotFrame title="Apodtion Count" plotDescription={App_AdoptionCount}>
                <AppAdoptionCount dataPath="app/adoption" />
            </PlotFrame>
            <PlotFrame title="Retention Rates" plotDescription={App_RetentionRates}>
                <AppRetentionRates dataPath="app/retention" />
            </PlotFrame>
        </PlotsArrangement> */}
        {/* OS Count */}
        <PlotsArrangement>
            <PlotFrame title="OS Count Over Time" plotDescription={App_OsCountOverTime}>
                <PropertyCount dataPath="app/os-count"/>
            </PlotFrame>
        </PlotsArrangement>
        {/* Device Model Count */}
        {/* Device Version Count */}
        <PlotsArrangement>
            <PlotFrame title="Device Model Count Over Time" plotDescription={App_DeviceModelCountOverTime}>
                <NestedPropertyCount dataPath="app/device-model-count" hasWebappOS />
            </PlotFrame>
            <PlotFrame title="Device Version Count Over Time" plotDescription={App_DeviceVersionCountOverTime}>
                <NestedPropertyCount dataPath="app/device-version-count" hasWebappOS />
            </PlotFrame>
        </PlotsArrangement>
        {/* App Version Count */}
        <PlotsArrangement>
            <PlotFrame title="App Version Count Over Time" plotDescription={App_AppVersionCountOverTime}>
                <NestedPropertyCount dataPath="app/app-version-count" />
            </PlotFrame>
        </PlotsArrangement>
        {/* Visited Screens */}
        <PlotsArrangement>
            <PlotFrame title="Visited Screens Over Time" plotDescription={App_VisitedScreensOverTime}>
                <PropertyCount dataPath="app/visited-screens-over-time" />
            </PlotFrame>
        </PlotsArrangement>
    </div >
}
