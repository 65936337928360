import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import {
  lineGraphLayout,
} from "../../../utils/plots/style/layouts";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";

type Props = {
  dataPath: string,
};

type Distribution = {
  [label: string]: number,
};

export default function SimpleBarPlotForDistribution({ dataPath }: Props) {
  const [data, setData] = useState<Distribution | null>(null);

  useEffect(() => {
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath)
          .then((fetchedData: Distribution) => {
            setData(fetchedData);
          })
      })
  }, []);

  let lables: string[] = [];
  let counts: number[] = [];
  if (data) {
    const dataArray = Object.entries(data);
    dataArray.sort((a, b) => b[1] - a[1]);
    lables = dataArray.map((item) => item[0]);
    counts = dataArray.map((item) => item[1]);
  }
  
  return (
    !!!data ? <LoadingSpinner /> :
      <Plot
        key={JSON.stringify(data)}
        style={{ width: "100%", height: "100%" }}
        data={[
          {
            x: lables,
            y: counts,
            type: "bar",
          }]}
        layout={{
          ...lineGraphLayout,
          xaxis: {
            // ...lineGraphLayout.xaxis,
            title: "Versions",
          },
          yaxis: {
            ...lineGraphLayout.yaxis,
            title: "Count",
          }
        }}
      />
  );
}
