import { useLocation } from "react-router";
import { NAV_LINKS } from "../../utils/nav-links";
import PlotsArrangement from "../../components/PlotsArrangement/indext";
import PlotFrame from "../../components/PlotFrame";
import RobotAdoptionCount from "../../plots/components/robot/RobotAdoptionCount";
import {
    Robot_ActiveUsersOverTime, Robot_AdoptionCount,
    BiweeklyFwVersionCountDescription,
    Robot_FrequencyOfRobotSessions,
    Robot_MeanSessionDurationByTimezoneOverTime,
    Robot_MeanSessionDurationOverTime,
    Robot_MostActiveFWVersionOverTime,
    Robot_MostActiveTimezonesByRicRev,
    Robot_RetentionRates,
    Robot_RobotSessionsLinedUp,
    Robot_UsageChangeRateOverTimeByRicRev,
    Robot_VersionDistributionOfActiveUsers,
    Robot_WarningMessagesOccurenciesByRicRevOverTime,
    Robot_WarrantyActiveUsersOverTime,
    WarrantyServiceWarningsPercentageOverTime,
    Robot_ActiveUsersCountOverTime
} from "../../utils/plot-descriptions";
import styles from "./styles.module.css";
import RobotRetentionRates from "../../plots/components/robot/RobotRetentionRates";
import PropertyCount from "../../plots/components/PropertyCount";
import RobotWarrantyServiceWarrnings from "../../plots/components/robot/RobotWarrantyServiceWarrnings";
import SimpleBarPlotForDistribution from "../../plots/components/SimpleBarPlotForDistribution";
import RobotActiveUsersOverTime from "../../plots/components/robot/RobotActiveUsersOverTime";
import PropertyCountOverTimeByTimezone from "../../plots/components/PropertyCountOverTimeByTimezone";
import SessionFrequencyByRicRevAndTimezone from "../../plots/components/robot/SessionFrequencyByRicRevAndTimezone";
import SessionsFreq2ByRicRevAndTimezone from "../../plots/components/robot/SessionsFreq2ByRicRevAndTimezone";
import MostActiveTimezone from "../../plots/components/robot/MostActiveTimezone";
import ChangeRateOverTimeByRicRev from "../../plots/components/robot/ChangeRateOverTimeByRicRev";
import MostActiveFwVersionOverTime from "../../plots/components/robot/MostActiveFwVersionOverTime";
import WarningMessagesOccurenciesByRicRevOverTime from "../../plots/components/robot/WarningMessagesOccurenciesByRicRevOverTime";

export default function RobotAnalyticsPageCmp() {
    const location = useLocation();


    return <div className={styles.container}>
        <h4 className={styles.title}>
            {NAV_LINKS.filter((link) => link.href === location.pathname)[0].title}{" "}
        </h4>
        <PlotsArrangement>
            <PlotFrame title="Apodtion Count" plotDescription={Robot_AdoptionCount}>
                <RobotAdoptionCount dataPath="robot/adoption" yAxisTitle="Unique Sessions" />
            </PlotFrame>
            <PlotFrame title="Retention Rates" plotDescription={Robot_RetentionRates}>
                <RobotRetentionRates dataPath="robot/retention" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Active Users Percentages" plotDescription={Robot_ActiveUsersOverTime}>
                <RobotActiveUsersOverTime dataPath="robot/active-users-over-time" />
            </PlotFrame>
            <PlotFrame title="Warranty Users Percentages" plotDescription={Robot_WarrantyActiveUsersOverTime}>
                <RobotActiveUsersOverTime dataPath="robot/warranty-active-users-over-time" isWarranty={true} />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Active Users Counts" plotDescription={Robot_ActiveUsersCountOverTime}>
                <RobotActiveUsersOverTime dataPath="robot/active-users-over-time" isCount={true} />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Mean Robot Duration (m) Over Time" plotDescription={Robot_MeanSessionDurationOverTime}>
                <RobotAdoptionCount dataPath="robot/mean-session-duration" yAxisTitle="Mean Session (M)" />
            </PlotFrame>
            <PlotFrame title="Mean Robot Duration (m) Over Time By Timezone" plotDescription={Robot_MeanSessionDurationByTimezoneOverTime}>
                <PropertyCountOverTimeByTimezone dataPath="robot/mean-session-duration-by-timezone-over-time" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Version Distribution of Active Users" plotDescription={Robot_VersionDistributionOfActiveUsers}>
                <SimpleBarPlotForDistribution dataPath="robot/active-users-version-distribution" />
            </PlotFrame>
        </PlotsArrangement>


        <PlotsArrangement>
            <PlotFrame title="Frequency of Robot Sessions" plotDescription={Robot_FrequencyOfRobotSessions}>
                <SessionFrequencyByRicRevAndTimezone dataPath="robot/session-frequency-by-timezone-and-ric-rev" />
            </PlotFrame>
            <PlotFrame title="Robot Sessions Lined Up" plotDescription={Robot_RobotSessionsLinedUp}>
                <SessionsFreq2ByRicRevAndTimezone dataPath="robot/session-frequency-by-timezone-and-ric-rev" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Most Active Timezones" plotDescription={Robot_MostActiveTimezonesByRicRev}>
                <MostActiveTimezone dataPath="robot/most-active-timezone-by-ric-rev" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Usage Change Rate Over Time" plotDescription={Robot_UsageChangeRateOverTimeByRicRev}>
                <ChangeRateOverTimeByRicRev dataPath="robot/rate-of-change-in-marty-usage-over-time" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Firmware Version Count Over Time" plotDescription={BiweeklyFwVersionCountDescription}>
                <PropertyCount dataPath="robot/fw-version-count" />
            </PlotFrame>
            <PlotFrame title="Most Active FW version Over Time" plotDescription={Robot_MostActiveFWVersionOverTime}>
                <MostActiveFwVersionOverTime dataPath="robot/most-active-fw-versions-by-period" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Warranty Service Warnings Percentage Over Time" plotDescription={WarrantyServiceWarningsPercentageOverTime}>
                <RobotWarrantyServiceWarrnings dataPath="robot/warranty-service-warnings/per-60" />
            </PlotFrame>
        </PlotsArrangement>

        <PlotsArrangement>
            <PlotFrame title="Warning Messages Occurencies by Ric Rev Over Time" plotDescription={Robot_WarningMessagesOccurenciesByRicRevOverTime}>
                <WarningMessagesOccurenciesByRicRevOverTime dataPath="robot/warning-messages-occurrence" />
            </PlotFrame>
        </PlotsArrangement>

    </div>
}
