import React from 'react';
import styles from './styles.module.css';

import { Link } from 'react-router-dom';
import { NAV_LINKS } from '../../utils/nav-links';



interface ButtonProps {
  label: string;
  link: string;
  imgSrc: string;
}

const Button: React.FC<ButtonProps> = ({ label, imgSrc, link }) => (
  <Link to={link} className={styles.link}>
    <button className={styles.button}>
      <img src={imgSrc} alt={label} className={styles.image} />
      <span className={styles.button_title}>{label}</span>
    </button>
  </Link>
);

const FlexButtons: React.FC = () => {

  return (
    <div className={styles.container}>
      {NAV_LINKS.map(({ title, img, href }) => (
        <Button
          key={title}
          label={title}
          imgSrc={img}
          link={href}
        />
      ))}
    </div>
  );
};

export default FlexButtons;
