import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import LoginPageCmp from "../pages/LoginPage";
import AppAnalyticsPageCmp from "../pages/AppAnalyticsPage";
import RobotAnalyticsPageCmp from "../pages/RobotAnalyticsPage";
import LearningSiteAnalyticsPageCmp from "../pages/LearningSiteAnalyticsPage";
import MartyBlocksAnalyticsPageCmp from "../pages/MartyBlocksAnalyticsPage";
import MachineLearningAnalyticsPage from "../pages/MachineLearningAnalyticsPage";
import PythonTranslatorAnalyticsPage from "../pages/PythonTranslatorAnalyticsPage";
import SensorsDashboardAnalyticsPage from "../pages/SensorsDashboardAnalyticsPage";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPageCmp />} />
      <Route path="/app" element={<AppAnalyticsPageCmp />} />
      <Route path="/robot" element={<RobotAnalyticsPageCmp />} />
      <Route path="/learning-site" element={<LearningSiteAnalyticsPageCmp />} />
      <Route path="/marty-blocks" element={<MartyBlocksAnalyticsPageCmp />} />
      <Route path="/machine-learning" element={<MachineLearningAnalyticsPage />} />
      <Route path="/python-translator" element={<PythonTranslatorAnalyticsPage />} />
      <Route path="/sensors-dashboard" element={<SensorsDashboardAnalyticsPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
