import styled, { keyframes } from "styled-components";
import GlobalColors from "../../styles/colors";
import { FONT_FAMILY } from "../../styles/constants";

const comeInFromTop = keyframes`
 0% {
  transform: translateY(-100%);
  }
  80% {
    transform: translateY(20%);
  }

  100%{
    transform: translateY(0);
  }
`;

const leaveToTop = keyframes`
 0% {
   transform: translateY(0);
  }
  20% {
    transform: translateY(20%);
  }
  
  100%{
    transform: translateY(-200%);
  }
`;
export const StyledModalBody = styled.div`
  /* padding-top: 10px; */
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
`;

type StyledModalProps = {
  $closing: boolean;
};

export const StyledModal = styled.div<StyledModalProps>`
  background: ${GlobalColors.DARK_GREY};
  border-radius: 15px;
  padding: 15px;
  animation: ${(props) => (props.$closing ? leaveToTop : comeInFromTop)} 0.7s
    ease-in;
  width: 75vw;
  /* height: 75vh; */
`;
export const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const StyledTitle = styled.h2`
  color: white;
  font-size: 30px;
  font-family: "BigNoodleTitling Oblique";
  display: inline-block;
`;

export const StyledModalTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCloseSymbol = styled.div`
  color: white;
  cursor: pointer;
  align-self: center;
  font-size: 10px;
  padding: 5px 7px;
  border: solid white 1px;
  border-radius: 50%;
  text-transform: uppercase;
  font-family: ${FONT_FAMILY.LATO_REGULAR};
  transition: all 0.2s;
  :hover {
    color: #ffcccb;
    border: solid #ffcccb 1px;
  }
`;
