const GlobalColors = {
  PRIMARY: '#37abc8',
  SECONDARY: '#ffda61',
  HOVER_PRIMARY: '#88cede',
  DARK_PRIMARY: '#157D91',
  HOVER_SECONDARY: '#ffe799',
  WHITE: '#ffffff',
  BLACK: '#222222',
  GREY_NOT_TOO_DARK: '#7F7F7F',
  HOVER_HEADER_LINK: '#37ABC8',
  DARK_GREY: '#262626',
  LIGHT_GREY: '#F2F2F2',
  LIGHT_GREY_2: '#dddddd',
  RED: '#ff0000',
  LIFE_RING_RED: '#d7525d',
  TEXT_H1: 'rgb(38, 38, 38)',
  TEXT_H2: 'rgb(34, 34, 34)',
  TEXT_H3: 'rgb(34, 34, 34)',
  TEXT_NORMAL: 'rgb(43, 43, 43)',
}

export default GlobalColors
