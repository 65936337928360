import { RobotWarrantyServiceWarnPercOverTimeGetResponse } from "../../types/robot";
import GenericProcessor from "./GenericProcessor";
import { lineGraphOptions } from "../../utils/plots/style/data-traces";
import { MostActiveFwVersionByPeriod, RateOfChangeInMartyUsageOverTime, RobotMostActiveTimezoneByRicRev, RobotSessionFrequencyByRicRev, WarningMessagesOccurrenceOverTime } from "@robotical/analytics-gatherer";
import { Daterange } from "../../params-analyzer/marty-blocks";

type Traces = { [warningTitle: string]: any };

export default class RobotProcessor {

  static getWarningMessagesOccurrenceOverTime(data: WarningMessagesOccurrenceOverTime | null) {
    const traces: Traces = {};
    if (!data) return null;
    for (const date in data) {
      const warningTitles = Object.keys(data[date]);
      for (const title of warningTitles) {
        if (!traces[title]) {
          traces[title] = {
            x: [],
            y: [],
            textposition: 'none',
            name: title,
            hovertemplate: [
              "Date: %{x}",
              "Count: %{y}",
            ].join("<br>"),
            hoverinfo: 'text',
            type: "scatter",
            mode: "lines+markers",
            marker: { size: 5 },
            line: { shape: "spline" },
          };
        }

        const trace = traces[title];
        trace.x.push(date);
        trace.y.push(data[date][title] || 0);
      }
    }

    const tracesArray = Object.values(traces);
    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(tracesArray);
    return {
      traces: tracesArray,
      smallerDate: smallerDate,
      largerDate: largerDate
    };
  }

  static mostActiveFwVersion(data: MostActiveFwVersionByPeriod[""] | null) {
    const traces: any[] = [];
    if (!data) return null;
    const dates = Object.keys(data);
    const values = dates.map((date) => data[date]);
    traces.push({
      ...lineGraphOptions(),
      x: dates.map(
        (stringDate) => new Date(stringDate)
      ),
      y: values,
      type: "scatter",
      mode: "lines+markers",
      name: "Most Active FW Version",
      hoverinfo: 'text',
      hovertemplate: [
        "Date: %{x}",
        `FW Version: %{y}`,
      ].join("<br>"),
    });
    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);
    return {
      traces,
      smallerDate,
      largerDate
    };
  }

  static getRateOfChangeInMartyUsageOverTime(data: RateOfChangeInMartyUsageOverTime | null) {
    const traces: any[] = [];
    if (!data) return null;
    const dates = Object.keys(data);
    const values = dates.map((date) => data[date]);
    traces.push({
      ...lineGraphOptions(),
      x: dates.map(
        (stringDate) => new Date(stringDate)
      ),
      y: values,
      type: "scatter",
      mode: "lines+markers",
      name: "Rate of Change",
      hoverinfo: 'text',
      hovertemplate: [
        "Date: %{x}",
        `Rate of Change: %{y:,.02f}`,
      ].join("<br>"),
    });
    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);
    return {
      traces,
      smallerDate,
      largerDate
    };
  }

  static mostActiveTimezonesByRicRev(data: RobotMostActiveTimezoneByRicRev[""] | null, topN: number, selectedDateRange: Daterange) {
    const traces: any[] = [];
    if (!data) return null;
    const timezones = Object.keys(data);
    const sortedTimezones = timezones.sort((a, b) => data[b] - data[a]);
    const topTimezones = sortedTimezones.slice(0, topN);
    const topTimezonesData = topTimezones.map((timezone) => data[timezone]);
    traces.push({
      ...lineGraphOptions(),
      labels: topTimezones,
      values: topTimezonesData,
      type: "pie",
      name: "Top Timezones",
      hoverinfo: 'label+percent',
      textinfo: 'label+percent',
      hole: 0.1,
    });

    return {
      traces,
    };
  }

  static warningsPercOverTimeToTraces = (
    data: RobotWarrantyServiceWarnPercOverTimeGetResponse | null
  ) => {
    const traces: Traces = {};
    if (!data) return null;
    for (const date in data) {
      const warningTitles = Object.keys(data[date]);
      for (const title of warningTitles) {
        if (!traces[title]) {
          traces[title] = {
            x: [],
            y: [],
            text: [],
            textposition: 'none',
            name: title,
            hovertemplate: [
              "Date: %{x}",
              "%{y:,.0f}%",
              "Total: %{text}",
            ].join("<br>"),
            hoverinfo: 'text',
            type: "scatter",
            mode: "lines+markers",
            marker: { size: 5 },
            line: { shape: "spline" },
          };
        }

        const trace = traces[title];
        trace.x.push(date);
        trace.y.push(data[date][title].perc || 0);
        trace.text.push(data[date][title].total || 0);
      }
    }

    const tracesArray = Object.values(traces);
    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(tracesArray);
    return {
      traces: tracesArray,
      smallerDate: smallerDate,
      largerDate: largerDate
    };
  };

  static activeUsersOverTimeToTraces = (
    data: { activeUsersPercentage: number, activeUsersCount: number, [key: string]: any }  | null,
    period: string,
    isForWarranty: boolean,
    isCount: boolean
  ) => {
    const traces: any[] = [];
    if (!data) return null;
    // for (const [key, value] of Object.entries(data)) {
      const periodData = data;

      const dates = Object.keys(periodData);
      const activeUsersPerc = dates.map((date) => periodData[date].activeUsersPercentage * 100);
      const activeUsersCount = dates.map((date) => periodData[date].activeUsersCount);

      traces.push({
        ...lineGraphOptions(),
        x: dates.map(
          (stringDate) => new Date(stringDate)
        ),
        y: isCount ? activeUsersCount : activeUsersPerc,
        mode: "lines+markers",
        name: period,
        text: isCount ? activeUsersPerc : activeUsersCount,
        hoverinfo: 'text',
        hovertemplate: [
          "Date: %{x}",
          isForWarranty ? `Warranty Users %${isCount ? "{text:,.0f}" : "{y:,.0f}"}%` : `Active Users %${isCount ? "{text:,.0f}" : "{y:,.0f}"}%`,
          `Active Users Count: %${isCount ? "{y}" : "{text}"}`,
        ].join("<br>"),
      })
    // }
    const tracesArray = Object.values(traces);
    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(tracesArray);
    return {
      traces: tracesArray,
      smallerDate: smallerDate,
      largerDate: largerDate
    };

  }


  /**
   * @description It returns the data grouped by the period and filtered by the timerange for each RIC REV.
   * @param data 
   * @param period when the data is grouped by hour, we show a histogram of hours in the day. When the data is grouped by day, we show a histogram of days in a week. When the data is grouped by month, we show a histogram of months in the year.
   * @param timerange filter the data to a specific range of time. if not provided, all data is used.
   * @returns traces: one trace for each ric rev.
   */
  static sessionFrequencyByTimezonesAndRicRev(data: RobotSessionFrequencyByRicRev | null, period: "hours" | "day" | "month" = "day", timerange?: { start: string, end: string }) {
    const traces: any[] = [];
    if (!data) return null;
    const ricRevs = Object.keys(data);

    // create bins for the histogram
    const bins = period === "hours" ? HOUR_BINS : period === "day" ? DAY_BINS : MONTH_BINS;

    for (const ricRev of ricRevs) {
      const sessionIds = Object.keys(data[ricRev]);
      const counter = Object.fromEntries(bins.map((bin) => [bin, 0]));
      for (const sessionId of sessionIds) {
        const session = data[ricRev][sessionId];
        const date = session.firstTimestamp;
        if (timerange) {
          const startDate = new Date(timerange.start);
          const endDate = new Date(timerange.end);
          const formattedDate = new Date(date);
          if (formattedDate < startDate || formattedDate > endDate) {
            continue;
          }
        }
        const label = RobotProcessor.labelTimestampGivenPeriod(date, period);
        if (!label) continue;
        counter[label] += 1;
      }

      const x = Object.keys(counter);
      const y = Object.values(counter);
      traces.push({
        ...lineGraphOptions(),
        x: x,
        y: y,
        name: "Rev: " + ricRev,
        type: "bar",
        hoverinfo: 'text',
        hovertemplate: [
          "Count: %{y}",
          period + ": %{x}",
          "RIC Rev: " + ricRev,
        ].join("<br>"),
      });
    }

    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);


    return {
      traces,
      smallerDate,
      largerDate
    }
  }

  /**
   * @description It returns the traces for a linegraph and barchart of the session by timezones and RIC REV.
   * @param data 
   * @returns traces: one trace for each ric rev
   */
  static sessionsFreq2ByTimezonesAndRicRev(data: RobotSessionFrequencyByRicRev | null, period: "hours" | "day" | "month" = "day") {
    const traces: any[] = [];
    if (!data) return null;
    const ricRevs = Object.keys(data);

    // Bar chart traces -- Counter for each day
    const counter: { [ricRev: string]: { [label: string]: number } } = {};
    for (const ricRev of ricRevs) {
      counter[ricRev] = {};
      const sessionIds = Object.keys(data[ricRev]);
      for (const sessionId of sessionIds) {
        const session = data[ricRev][sessionId];
        const date = session.firstTimestamp;
        if (!date) continue;
        if (!counter[ricRev][date]) counter[ricRev][date] = 0;
        counter[ricRev][date] += 1;
      }
    }

    for (const ricRev of ricRevs) {
      const x = Object.keys(counter[ricRev]).map((date) => new Date(date));
      if (period === "day") {
        x.forEach((date) => date.setHours(0, 0, 0, 0));
      }
      if (period === "month") {
        x.forEach((date) => date.setHours(0, 0, 0, 0));
        x.forEach((date) => date.setDate(1));
      }
      const y = Object.values(counter[ricRev]);
      traces.push({
        ...lineGraphOptions(),
        x: x.map((date) => new Date(date)),
        y: y,
        name: "Rev: " + ricRev,
        type: "bar",
        marker: {
          opacity: 0.2,
        },
      });
    }

    const { smallerDate, largerDate } = GenericProcessor.smallerAndLargerDate(traces);

    return {
      traces,
      smallerDate,
      largerDate
    }
  }

  static labelTimestampGivenPeriod = (timestamp: string, period: "hours" | "day" | "month") => {
    const date = new Date(timestamp);
    if (period === "hours") {
      const hour = date.getHours();
      for (let i = 0; i < HOUR_BINS.length; i++) {
        const [start, end] = HOUR_BINS[i].split(" - ");
        const [startHour, endHour] = [parseInt(start.split(":")[0]), parseInt(end.split(":")[0])];
        if (hour >= startHour && hour < endHour) {
          return HOUR_BINS[i];
        }
      }
    } else if (period === "day") {
      const day = date.toLocaleString('en-US', { weekday: 'long' });
      for (let i = 0; i < DAY_BINS.length; i++) {
        if (day === DAY_BINS[i]) {
          return DAY_BINS[i];
        }
      }
    } else if (period === "month") {
      const month = date.toLocaleString('en-US', { month: 'long' });
      for (let i = 0; i < MONTH_BINS.length; i++) {
        if (month === MONTH_BINS[i]) {
          return MONTH_BINS[i];
        }
      }
    }
  }
}


const HOUR_BINS = ["00:00 - 02:00", "02:00 - 04:00", "04:00 - 06:00", "06:00 - 08:00", "08:00 - 10:00", "10:00 - 12:00", "12:00 - 14:00", "14:00 - 16:00", "16:00 - 18:00", "18:00 - 20:00", "20:00 - 22:00", "22:00 - 00:00"];
const DAY_BINS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const MONTH_BINS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];