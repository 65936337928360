import { useContext, useState } from "react";
import { NavigationContainer } from "./styles";
import { CredentialsCookie } from "../../../utils/cookies/user-credentials";
import UserCredentialsContext from "../../../store/user-credentials-context";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { NAV_LINKS } from "../../../utils/nav-links";


export default function Navigation() {
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const location = useLocation();
  const credentialsContext = useContext(UserCredentialsContext);
  const sandwitchClickHandler = () => {
    setShowSideDrawer((oldState) => !oldState);
  };

  const navigate = useNavigate();

  const navigationLinksJsx = NAV_LINKS.map((link) => {
    const isLinkSelectedClass = location.pathname === link.href ? "active" : "";
    return (
      <li key={link.href} className={isLinkSelectedClass}>
        <Link to={link.href}>{link.title}</Link>
      </li>
    );
  });

  const logoutHandler = () => {
    CredentialsCookie.removeCookie();
    credentialsContext.setUserCredentials(false);
    navigate("/login");
  };

  return (
    <NavigationContainer>
      <>
        <div className="navigation-title">
          <h3>
            Robotical | <span>Analytics</span>
          </h3>
        </div>
        <hr />
        <button className="logout-button" onClick={logoutHandler}>
          logout
        </button>
        <ul className="navigation-links-main">{navigationLinksJsx}</ul>
        <div
          className="navigation-sandwich-container"
          onClick={sandwitchClickHandler}
        >
          <div
            className={`navigation-sandwich ${
              showSideDrawer ? "navigation-sandwich-clicked" : ""
            }`}
          ></div>
        </div>
        {showSideDrawer && (
          <div className="navigation-side-drawer-backdrop">
            <div className="navigation-side-drawer">
              <ul className="navigation-links-side-drawer">
                {navigationLinksJsx}
              </ul>
            </div>
          </div>
        )}
      </>
    </NavigationContainer>
  );
}
