import { FormEvent, useContext, useState } from "react";
import UserCredentialsContext from "../../store/user-credentials-context";
import { CredentialsCookie } from "../../utils/cookies/user-credentials";
import { Container } from "./styles";
import { useNavigate } from "react-router";

export default function LoginPageCmp() {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const credentialsContext = useContext(UserCredentialsContext);

  const formSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === "martyRocks") {
      CredentialsCookie.setCookie(true);
      credentialsContext.setUserCredentials(true);
      navigate("/");
    } else {
      setErrorMessage("Wrong password");
    }
  };

  return (
    <Container>
      <div className="login-div">
        <h2>Login</h2>
        <hr />
        <form className="login-form" onSubmit={formSubmitHandler}>
          <p>{errorMessage}</p>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
          <button>Login</button>
        </form>
      </div>
    </Container>
  );
}
