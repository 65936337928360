import { Container } from "./styles";


type PlotsArrangementProps = {
    children: React.ReactNode | React.ReactNode[];
}

export default function PlotsArrangement({children}: PlotsArrangementProps) {
  return (
    <Container>
      {children}
    </Container>
  );
}
