import { Layout, Legend, RangeSelector, RangeSlider } from "plotly.js";
import GlobalColors from "../../../styles/colors";
import { FONT_FAMILY } from "../../../styles/constants";

const COLORS = [
  GlobalColors.PRIMARY,
  GlobalColors.DARK_PRIMARY,
  GlobalColors.HOVER_PRIMARY,
  GlobalColors.SECONDARY,
  GlobalColors.HOVER_SECONDARY,
  GlobalColors.LIFE_RING_RED,
];

const randomColor = () => {
  const idx = Math.floor(Math.random() * COLORS.length);
  return COLORS[idx];
};

export function truncateTraceName(str: string, n: number) {
  const strWithoutMv2 = str.replace("mv2_", "");
  return (strWithoutMv2.length > n) ? strWithoutMv2.substr(0, n - 1) + '...' : strWithoutMv2;
}

export const axisFontStyle = {
  family: FONT_FAMILY.LATO_REGULAR,
  color: "white",
  size: 14,
};

export const plotMargins = {
  l: 60,
  r: 20,
  b: 60,
  t: 20,
  pad: 2,
};

export const legendInPlot: Partial<Legend> = {
  xanchor: "right",
  x: 1,
  y: 1,
  bgcolor: GlobalColors.LIGHT_GREY_2,
  font: {
    color: "black",
  },
};

export const legendOutsidePlot: Partial<Legend> = {
  // xanchor: "right",
  x: 1,
  y: 0.5,
  // bgcolor: GlobalColors.LIGHT_GREY_2,
  font: {
    color: "white",
  },
};

export const barPlotLayout = (): Partial<Layout> => {
  return {
    autosize: true,
    margin: plotMargins,
    bargap: 0.5,
    legend: legendOutsidePlot,
    yaxis: {
      title: "Count",
      titlefont: {
        ...axisFontStyle,
        size: 16,
      },
      gridcolor: "rgba(22, 67, 78, .8)",
      tickfont: axisFontStyle,
    },
    xaxis: {
      tickfont: axisFontStyle,
    },
    plot_bgcolor: GlobalColors.DARK_GREY,
    paper_bgcolor: GlobalColors.DARK_GREY,
  };
};

export const rangeSelector: Partial<RangeSelector> = {
  buttons: [
    {
      count: 1,
      label: "1m",
      step: "month",
      stepmode: "backward",
    },
    {
      count: 6,
      label: "6m",
      step: "month",
      stepmode: "backward",
    },
    { step: "all" },
  ],
};

export const lineGraphLayout: Partial<Layout> = {
  autosize: true,
  margin: plotMargins,
  bargap: 0.5,
  legend: legendOutsidePlot,
  yaxis: {
    autorange: true,
    titlefont: {
      ...axisFontStyle,
      size: 16,
    },
    gridcolor: "rgba(22, 67, 78, .8)",
    tickfont: axisFontStyle,
  },
  xaxis: {
    tickfont: axisFontStyle,
    type: "date",
    rangeselector: rangeSelector,
  },
  plot_bgcolor: GlobalColors.DARK_GREY,
  paper_bgcolor: GlobalColors.DARK_GREY,
};

export const rangeSlider = (low: Date, high: Date): Partial<RangeSlider> => {
  return {
    range: [low, high],
  };
};
