import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import Router from './router';
import { UserCredentialsContextProvider } from './store/user-credentials-context';
import "./styles/typography.css";
import "./styles/global.css";

function App() {
  return (
    <BrowserRouter>
      <UserCredentialsContextProvider>
        <Layout>
          <Router />
        </Layout>
      </UserCredentialsContextProvider>
    </BrowserRouter>
  );
}

export default App;
