import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager, RobotSessionFrequencyByTimezoneAndRicRev } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import { Daterange, getExpandedPlotParams, getMostUsedBlocksParams, setParams } from "../../../params-analyzer/marty-blocks";
import styles from "./styles.module.css";
import RobotProcessor from "../../data-processors/RobotProcessor";
import {
    lineGraphLayout,
} from "../../../utils/plots/style/layouts";

type Props = {
    dataPath: string,
    title?: string,
}

export default function SessionFrequencyByRicRevAndTimezone({ dataPath, title }: Props) {
    const [timezones, setTimezones] = useState<string[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<"hours" | "day" | "month">("hours");
    const [selectedDateRange, setSelectedDateRange] = useState<Daterange>({ start: "2020-01-01", end: "2030-01-01" });
    const [selectedTimezone, setSelectedTimezone] = useState<string>("");

    const [hasTimezonesFetched, setHasTimezonesFetched] = useState(false);

    const [data, setData] = useState<RobotSessionFrequencyByTimezoneAndRicRev[""] | null>(null);
    const timezoneParamChangedByUser = useRef(false);
    const periodParamChangedByUser = useRef(false);
    const dateRangeParamChangedByUser = useRef(false);

    useEffect(() => {
        // get all timezones
        DatabaseManager.getInstance()
            .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
            .then((db) => {
                db.get(dataPath)
                    .then((fetchedData: RobotSessionFrequencyByTimezoneAndRicRev) => {
                        if (Object.keys(fetchedData).length > 0) {
                            setTimezones(Object.keys(fetchedData));
                            setHasTimezonesFetched(true);
                            const expandedPlot = getExpandedPlotParams();
                            if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
                                const params = getMostUsedBlocksParams();
                                if (!params.timezone) {
                                    // setSelectedTimezone(Object.keys(fetchedData)[0]);
                                    setSelectedTimezone("All");
                                }
                            } else {
                                // setSelectedTimezone(Object.keys(fetchedData)[0]);
                                setSelectedTimezone("All");
                            }
                        }
                    });
            });
    }, []);

    useEffect(() => {
        if (!hasTimezonesFetched) return;
        const params = getMostUsedBlocksParams();
        const expandedPlot = getExpandedPlotParams();

        if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
            if (params.timezone) {
                setSelectedTimezone(params.timezone);
            } else {
                setParams({ timezone: selectedTimezone });
            }
            if (params.period) {
                setSelectedPeriod(params.period as "hours" | "day" | "month");
            } else {
                setParams({ period: selectedPeriod });
            }
            if (params.dateRange) {
                setSelectedDateRange(params.dateRange as Daterange);
            } else {
                const selectedDateRangeString = `${selectedDateRange.start} - ${selectedDateRange.end}`;
                setParams({ dateRange: selectedDateRangeString });
            }
        }
    }, [hasTimezonesFetched]);

    useEffect(() => {
        const expandedPlot = getExpandedPlotParams();
        if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
            timezoneParamChangedByUser.current && setParams({ timezone: selectedTimezone });
            periodParamChangedByUser.current && setParams({ period: selectedPeriod });
            if (dateRangeParamChangedByUser.current) {
                const selectedDateRangeString = `${selectedDateRange.start} - ${selectedDateRange.end}`;
                setParams({ dateRange: selectedDateRangeString });
            }
        }
    }, [selectedTimezone, selectedPeriod, selectedDateRange]);


    useEffect(() => {
        if (!selectedTimezone) return;
        DatabaseManager.getInstance()
            .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
            .then((db) => {
                db.get(dataPath + "/" + selectedTimezone)
                    .then((fetchedData: RobotSessionFrequencyByTimezoneAndRicRev[""]) => {
                        setData(fetchedData);
                    })
            })
    }, [selectedTimezone]);

    const onTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        timezoneParamChangedByUser.current = true;
        setSelectedTimezone(e.target.value);
    }

    const onPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        periodParamChangedByUser.current = true;
        setSelectedPeriod(e.target.value as "hours" | "day" | "month");
    }

    const onDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dateRangeParamChangedByUser.current = true;
        const id = e.target.id;
        const value = e.target.value;
        setSelectedDateRange((prev) => {
            return {
                ...prev,
                [id]: value
            }
        });
    }

    const processedData = RobotProcessor.sessionFrequencyByTimezonesAndRicRev(data, selectedPeriod, selectedDateRange);

    return (
        !!!data ? <LoadingSpinner /> :
            <>
                <div className={styles.parameters}>
                    <div className={styles.topN}>
                        <label htmlFor="selectedTimezone">Timezone: </label>
                        <select
                            id="selectedTimezone"
                            value={selectedTimezone}
                            onChange={onTimezoneChange}
                        >
                            {timezones.map((timezone) => <option key={timezone} value={timezone}>{timezone}</option>)}
                        </select>
                        <label htmlFor="selectedPeriod">Period: </label>
                        <select
                            id="selectedPeriod"
                            value={selectedPeriod}
                            onChange={onPeriodChange}
                        >
                            <option value="hours">Hours</option>
                            <option value="day">Day</option>
                            <option value="month">Month</option>
                        </select>
                    </div>
                    <div className={styles.topN}>
                        <label htmlFor="start">Start: </label>
                        <input
                            type="date"
                            id="start"
                            value={selectedDateRange.start}
                            onChange={onDateRangeChange}
                            className={styles.dateInput}
                        />

                        <label htmlFor="end">End: </label>
                        <input
                            type="date"
                            id="end"
                            value={selectedDateRange.end}
                            onChange={onDateRangeChange}
                            className={styles.dateInput}
                        />
                    </div>
                </div>
                <Plot
                    key={JSON.stringify(processedData?.traces)}
                    style={{ width: "100%", height: "100%" }}
                    data={processedData?.traces || []}
                    layout={{
                        ...lineGraphLayout,
                        xaxis: {
                            tickfont: lineGraphLayout.xaxis?.tickfont,
                        },
                        yaxis: {
                            ...lineGraphLayout.yaxis,
                            title: "Frequency",
                        }
                    }}
                />
            </>
    );
}
