import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import { lineGraphLayout } from "../../../utils/plots/style/layouts";
import styles from "./styles.module.css";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DatabaseEnum, DatabaseManager, RobotMostActiveTimezoneByRicRev } from "@robotical/analytics-gatherer/dist"
import { processedDataConfig } from "../../../dbConfigs";
import { Daterange, getExpandedPlotParams, getMostUsedBlocksParams, setParams } from "../../../params-analyzer/marty-blocks";
import RobotProcessor from "../../data-processors/RobotProcessor";

type Props = {
  dataPath: string,
  title?: string,
  yAxesTitle?: string,
};

export default function MostActiveTimezone({ dataPath, title, yAxesTitle }: Props) {
  const [data, setData] = useState<RobotMostActiveTimezoneByRicRev[""] | null>(null);
  const [ricRevs, setRicRevs] = useState<string[]>([]);
  const [topN, setTopN] = useState(5);
  const [selectedDateRange, setSelectedDateRange] = useState<Daterange>({ start: "2020-01-01", end: "2030-01-01" });
  const [selectedRicRev, setSelectedRicRev] = useState<string>("");

  const [hasRicRevFetched, setHasRicRevFetched] = useState(false);

  const topNParamChangedByUser = useRef(false);
  const dateRangeParamChangedByUser = useRef(false);
  const ricRevParamChangedByUser = useRef(false);

  useEffect(() => {
    // get all ricRev
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath)
          .then((fetchedData: RobotMostActiveTimezoneByRicRev) => {
            if (Object.keys(fetchedData).length > 0) {
              setRicRevs(Object.keys(fetchedData));
              setHasRicRevFetched(true);
              const expandedPlot = getExpandedPlotParams();
              if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
                const params = getMostUsedBlocksParams();
                if (!params.ricRev) {
                  setSelectedRicRev("All");
                }
              } else {
                setSelectedRicRev("All");
              }
            }
          });
      });
  }, []);

  useEffect(() => {
    const params = getMostUsedBlocksParams();
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      if (params.topN) {
        setTopN(params.topN);
      } else {
        setParams({ topN: topN });
      }
      if (params.dateRange) {
        setSelectedDateRange(params.dateRange as Daterange);
      } else {
        const selectedDateRangeString = `${selectedDateRange.start} - ${selectedDateRange.end}`;
        setParams({ dateRange: selectedDateRangeString });
      }
      if (params.ricRev) {
        setSelectedRicRev(params.ricRev);
      } else {
        setParams({ ricRev: selectedRicRev });
      }
    }

  }, [hasRicRevFetched]);

  useEffect(() => {
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      topNParamChangedByUser.current && setParams({ topN: topN });
      ricRevParamChangedByUser.current && setParams({ ricRev: selectedRicRev });
      if (dateRangeParamChangedByUser.current) {
        const selectedDateRangeString = `${selectedDateRange.start} - ${selectedDateRange.end}`;
        setParams({ dateRange: selectedDateRangeString });
      }
    }
  }, [topN, selectedRicRev, selectedDateRange]);

  useEffect(() => {
    if (!selectedRicRev) return;
    DatabaseManager.getInstance()
      .initializeOrGetDatabase(DatabaseEnum.PROCESSED_DATA, processedDataConfig, DatabaseEnum.PROCESSED_DATA)
      .then((db) => {
        db.get(dataPath + `/${selectedRicRev}`)
          .then((fetchedData: RobotMostActiveTimezoneByRicRev[""]) => {
            setData(fetchedData);
          })
      })
  }, [selectedRicRev]);

  const onTopNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopN(parseInt(e.target.value));
    topNParamChangedByUser.current = true;
  };

  const onDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dateRangeParamChangedByUser.current = true;
    const id = e.target.id;
    const value = e.target.value;
    setSelectedDateRange((prev) => {
      return {
        ...prev,
        [id]: value
      }
    });
  }

  const onRicRevChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    ricRevParamChangedByUser.current = true;
    setSelectedRicRev(e.target.value);
  }

  const processedData = RobotProcessor.mostActiveTimezonesByRicRev(data, topN, selectedDateRange);

  return (
    !!!data ? <LoadingSpinner /> :
      <>
        <div className={styles.parameters}>
          <div className={styles.topN}>
            <label htmlFor="topN">Top N: </label>
            <input
              id="topN"
              type="number"
              min="1"
              value={topN}
              onChange={onTopNChange}
            />
            <label htmlFor="selectedRicRev">RicRev: </label>
            <select
              id="selectedRicRev"
              value={selectedRicRev}
              onChange={onRicRevChange}
            >
              {ricRevs.map((ricRev) => <option key={ricRev} value={ricRev}>{ricRev}</option>)}
            </select>
          </div>
          <div className={styles.topN}>
            <label htmlFor="start">Start: </label>
            <input
              type="date"
              id="start"
              value={selectedDateRange.start}
              onChange={onDateRangeChange}
              className={styles.dateInput}
            />

            <label htmlFor="end">End: </label>
            <input
              type="date"
              id="end"
              value={selectedDateRange.end}
              onChange={onDateRangeChange}
              className={styles.dateInput}
            />
          </div>
        </div>
        <Plot
          key={JSON.stringify(processedData)}
          style={{ width: "100%", height: "100%" }}
          data={processedData?.traces || []}
          layout={{
            ...lineGraphLayout,
            xaxis: {
              ...lineGraphLayout.xaxis,
            },
            yaxis: {
              ...lineGraphLayout.yaxis,
              title: yAxesTitle || "Count"
            }
          }}
        />
      </>
  );
}
