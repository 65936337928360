import { createContext, useState } from "react";

type UserCredentialsContextTypes = {
  isLoggedIn: boolean;
  setUserCredentials: (isLoggedIn: boolean) => void;
};

const UserCredentialsContext = createContext<UserCredentialsContextTypes>({
  isLoggedIn: false,
  setUserCredentials: () => {}
});

type UserCredentialsContextProviderTypes = {
  children: React.ReactNode;
};
export function UserCredentialsContextProvider({
  children,
}: UserCredentialsContextProviderTypes) {
  const [selectedUserCredentials, setSelectedUserCredentials] = useState(false);

  const selectUserCredentialsHandler = (isLoggedIn: boolean) => {
    setSelectedUserCredentials(isLoggedIn);
  };

  const context = {
    isLoggedIn: selectedUserCredentials,
    setUserCredentials: selectUserCredentialsHandler,
  };

  return (
    <UserCredentialsContext.Provider value={context}>
      {children}
    </UserCredentialsContext.Provider>
  );
}

export default UserCredentialsContext;
